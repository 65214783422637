import React from 'react'
import Button from '@components/atoms/Button'
import Container from '@components/atoms/Container'
import Footer from '@components/organisms/footer'
import Layout from '@components/organisms/Layout'
import Navigation from '@components/organisms/navigation'
import SEO from '@containers/SEO'
import dictionary from '@netlify/site-settings/dictionary.yml'
import '../style.404.scss'

const PageTemplate = props => {

    const langPage = ['en', 'ja']

    const page = {
        title: '404'
    }

    return (
        <>
            <Layout page={page}>
                <SEO errorPageTitle={dictionary.english.errorPagePageTitle} pageLangs={langPage} pageTitle={dictionary.english.errorPageTitle} lang='en' />
                <Navigation pageLangs={langPage} lang='en' />
                <Container>
                    <div className="hudl-error-block__content">
                        <h1 className="hudl-404-title hudl-alpha-type">{dictionary.english.errorPageTitle}</h1>
                        <h2 className="hudl-404-subtitle hudl-gamma-type">{dictionary.english.errorPageMessage}</h2>
                        <Button variant="secondary" to="/" className="hudl-button hudl-button--secondary-button">{dictionary.english.errorPageLinkText}</Button>
                    </div>
                </Container>
                <Footer pageLangs={langPage} lang='en' />
            </Layout>
        </>
    )
}

export default PageTemplate
